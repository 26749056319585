<template>
    <div>
        <table-component :table="table">
            <template #default="scope">
                <div v-if="scope.item.prop == 'wine_details'">
                    <!-- 商品信息 start -->
                    <div class="commodity-table">
                        <div v-for="(item,index) of scope.scope.row[scope.item.prop]" :key="index">
                            <div>
                                <span>{{item.wine_name}}</span>
                                <span>&nbsp;剩余{{item.num}}{{item.allowance_unit}}</span>
                                <span v-if="item.take_num>0" class="surplus-num">（已取出 * {{item.take_num}}{{item.allowance_unit}}）</span>
                            </div>
                            <div>
                                <span><el-progress :percentage="item.allowance"></el-progress></span>
                                <span>过期时间:{{item.expiration_time}}</span>
                            </div>
                        </div>
                    </div>
                    <!-- 商品信息 end -->
                </div>
                <div v-else-if="scope.item.prop == 'name'">
                    <div>{{scope.scope.row.name}}</div>
                    <div>{{scope.scope.row.phone}}</div>
                </div>
                <div v-else-if="scope.item.prop == 'status'">
                    <el-tag v-if="wine_status_config[scope.scope.row[scope.item.prop]]" :type="scope.scope.row.status == 9 || scope.scope.row.status == 10?'danger':''">{{wine_status_config[scope.scope.row[scope.item.prop]]}}</el-tag>
                    <span v-else>--</span>
                </div>
                <div v-else>{{scope.scope.row[scope.item.prop]}}</div>
            </template>
        </table-component>
    </div>
</template>
<script>
import tableComponent from '@/components/table'
import {saveWineMixins} from '@/mixins'
export default {
    components:{
        tableComponent
    },
    mixins:[saveWineMixins],
    props:{
        list:[]
    },
    watch:{
        list:{
            handler(newVal){
                this.table.list = newVal
            },
            deep:true
        }
    },
    data(){
        return{
            table:{
                column_key:[
                    {
                        prop:'alcohol_odd_num',
                        label:'存酒单号'
                    },
                    {
                        prop:'storage_station',
                        label:'存酒台位'
                    },
                    // {
                    //     prop:'take_station',
                    //     label:'取酒台位'
                    // },
                    {
                        prop:'name',
                        label:'客户信息'
                    },
                    {
                        prop:'operator_name',
                        label:'操作人'
                    },
                    {
                        prop:'created_at',
                        label:'存酒时间',
                        width:150
                    },
                    {
                        prop:'wine_details',
                        label:'商品',
                        width:400
                    },
                    // {
                    //     prop:'expiration_time',
                    //     label:'过期时间',
                    // },
                    {
                        prop:'status',
                        label:'状态',
                    },
                    // {
                    //     prop:'control',
                    //     label:'操作',
                    //     width:150
                    // }
                ],
                list:[]
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.surplus-num{
    color:red;
}
</style>