<template>
    <div>
        <table-component :table="table">
            <template #default="scope">
                <div v-if="scope.item.prop == 'wine_details'">
                    <!-- 商品信息 start -->
                    <div class="commodity-table">
                        <div v-for="(item,index) of scope.scope.row[scope.item.prop]" :key="index" class="commodity-item">
                            <div>
                                <div>
                                    <span>{{item.wine_name}}</span>
                                    <span>&nbsp;剩余{{item.num}}{{item.allowance_unit}}</span>
                                    <span v-if="item.take_num>0" class="surplus-num">（已取出 * {{item.take_num}}{{item.allowance_unit}}）</span>
                                </div>
                                <div>
                                    <span><el-progress :percentage="item.allowance"></el-progress></span>
                                    <span>过期时间:{{item.expiration_time}}</span>
                                </div>
                            </div>
                            <div class="global-ml-10">
                                <div>
                                    <!-- <el-button size="mini" type="danger" @click="takeout(scope.scope.row,6)" v-allow="['overdueLibrary']">过期入库</el-button> -->
                                </div>
                                <div>
                                    <el-button size="mini" type="primary" @click="delayDue(item)" v-allow="['overtime']">延长到期</el-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 商品信息 end -->
                </div>
                <!-- <div v-else-if="scope.item.prop == 'control'">
                    <el-button size="mini" type="danger" @click="takeout(scope.scope.row,6)" v-allow="['overdueLibrary']">过期入库</el-button>
                    <el-button size="mini" type="primary" @click="delayDue(scope.scope.row)" v-allow="['overtime']">延长到期</el-button>
                </div> -->
                <div v-else-if="scope.item.prop == 'name'">
                    <div>{{scope.scope.row.name}}</div>
                    <div>{{scope.scope.row.phone}}</div>
                </div>
                <div v-else-if="scope.item.prop == 'status'">
                    <el-tag>已过期</el-tag>
                </div>
                <div v-else>{{scope.scope.row[scope.item.prop]}}</div>
            </template>
        </table-component>

        <!-- 延迟到期 start -->
        <el-dialog
            :title="delay_due_config.title"
            v-model="delay_due_config.is_show"
            width="360px"
            :show-close="false"
            :before-close="handleClose">
            <div>
                <el-form ref="form" :model="delay_due_config.form" size="mini">
                    <el-form-item label="到期时间：">
                        <el-date-picker
                            v-model="delay_due_config.form.time"
                            value-format="YYYY-MM-DD"
                            type="date"
                            placeholder="选择日期时间"
                            :disabled-date="disabledDate">
                        </el-date-picker>
                    </el-form-item>
                </el-form>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="cleanFun">取消</el-button>
                    <el-button @click="extendFun" type="primary">确认</el-button>
                </span>
            </template>
        </el-dialog>
        <!-- 延迟到期 end -->
        
    </div>
</template>
<script>
import tableComponent from '@/components/table'
import {saveWineMixins} from '@/mixins'
import { wineWarehouseApi } from '@/api'
export default {
    components:{
        tableComponent
    },
    mixins:[saveWineMixins],
    props:{
        list:[]
    },
    watch:{
        list:{
            handler(newVal){
                this.table.list = newVal
            },
            deep:true
        }
    },
    data(){
        return{
            table:{
                column_key:[
                    {
                        prop:'alcohol_odd_num',
                        label:'存酒单号'
                    },
                    {
                        prop:'storage_station',
                        label:'存酒台位'
                    },
                    {
                        prop:'name',
                        label:'客户信息'
                    },
                    {
                        prop:'operator_name',
                        label:'操作人'
                    },
                    {
                        prop:'sales_name',
                        label:'销售'
                    },
                    {
                        prop:'created_at',
                        label:'存酒时间',
                        width:150
                    },
                    // {
                    //     prop:'expiration_time',
                    //     label:'过期时间'
                    // },
                    {
                        prop:'status',
                        label:'状态'
                    },
                    {
                        prop:'wine_details',
                        label:'商品',
                        width:400
                    },
                    // {
                    //     prop:'control',
                    //     label:'操作',
                    //     width:300
                    // }
                ],
                list:[]
            },
            delay_due_config:{
                title:'延迟到期',
                is_show:false,
                form:{
                    time:'',
                    expiration_time:''
                },
                row:{}
            },
        }
    },
    methods:{
        disabledDate(e){
            let time = new Date(this.delay_due_config.form.expiration_time)
            if(time.getTime() > e.getTime()){
                return true
            }
        },
        delayDue(row){
            this.delay_due_config.is_show = true
            this.delay_due_config.row = row
            this.delay_due_config.form.expiration_time = row.expiration_time
        },
        cleanFun(){
            this.delay_due_config.is_show = false
        },
        //延长到期时间
        extendFun(){
            if(!this.delay_due_config.form.time){
                this.$message.warning('请输入时间')
                return
            }

            wineWarehouseApi.wineProlongTime({
                storing_wine_info_ids:[this.delay_due_config.row.storing_wine_info_id],
                expiration_time:this.delay_due_config.form.time
            }).then(res=>{
                this.$message.success('操作成功')
                this.cleanFun()
                this.$emit('refreshList')
            })
        },
    }
}
</script>
<style lang="scss" scoped>
    .commodity-table{
        .commodity-item{
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
</style>