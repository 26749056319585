<template>
    <div class="wine-warehouse-page">
        
        <div class="tab-box-containter">
            <div>
                <el-tabs v-model="tab_config.tab_id" type="card" @tab-click="handleClick">
                    <el-tab-pane :label="tab_item.label" :name="tab_item.id" v-for="(tab_item,index) of tab_config.list" :key="index">
                        <div>
                            <el-form :model="search_config.form" label-width="100px" :inline="true">
                                <el-form-item label="日期筛选">
                                    <!-- <el-date-picker value-format="YYYY-MM-DD" :clearable="false" v-model="search_config.form.times" type="daterange" start-placeholder="开始时间" end-placeholder="结束时间"></el-date-picker> -->
                                    <el-select v-model="search_config.form.time_type" placeholder="请选择" class="order-type" @change="typeChange">
                                        <el-option v-for="(item,index) of search_config.type_options" :value="item.id" :label="item.label" :key="index"></el-option>
                                    </el-select>
                                    <el-date-picker :clearable="false" value-format="YYYY-MM-DD HH:mm:ss" v-if="search_config.form.time_type == 1" v-model="search_config.form.times" type="datetimerange" start-placeholder="开始时间" end-placeholder="结束时间"></el-date-picker>
                                    <el-date-picker :clearable="false" value-format="YYYY-MM-DD" v-else v-model="search_config.form.times" type="daterange" start-placeholder="开始时间" end-placeholder="结束时间"></el-date-picker>
                                </el-form-item>
                                <el-form-item label="存酒单号" v-if="isShow('alcohol_sub_odd_num')">
                                    <el-input v-model="search_config.form.alcohol_sub_odd_num" placeholder="请输入单号" @keyup.enter="searchFun"></el-input>
                                </el-form-item>
                                <!-- 这个存酒单号 在取酒显示的 -->
                                <el-form-item label="存酒单号" v-if="isShow('wine_storage_num')">
                                    <el-input v-model="search_config.form.wine_storage_num" placeholder="请输入单号" @keyup.enter="searchFun"></el-input>
                                </el-form-item>
                                <el-form-item label="取酒单号" v-if="isShow('take_alcohol_odd_num')">
                                    <el-input v-model="search_config.form.take_alcohol_odd_num" placeholder="请输入单号" @keyup.enter="searchFun"></el-input>
                                </el-form-item>
                                <el-form-item label="存酒台位" v-if="isShow('storage_station')">
                                    <el-input v-model="search_config.form.storage_station" placeholder="请输入台位名称" @keyup.enter="searchFun"></el-input>
                                </el-form-item>
                                <el-form-item label="取酒台位" v-if="isShow('take_station')">
                                    <el-input v-model="search_config.form.take_station" placeholder="请输入台位名称" @keyup.enter="searchFun"></el-input>
                                </el-form-item>
                                <el-form-item label="客户信息" v-if="isShow('member')">
                                    <el-input v-model="search_config.form.member" placeholder="请输入姓名或手机号" @keyup.enter="searchFun"></el-input>
                                </el-form-item>
                                <el-form-item label="">
                                    <el-button type="primary" @click="searchFun">查询</el-button>
                                    <el-button @click="resetFun">重置</el-button>
                                </el-form-item>
                            </el-form>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
            
            <!-- 不能放el-tab-pane里面遍历，数据会错乱 -->
            <component :is="tab_config.tab_name" :list="table.list" @refreshList="getList"></component>
            
            <div class="pagination-box">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pagination.page"
                    :page-sizes="pagination.page_sizes"
                    :page-size="pagination.page_size"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pagination.total">
                </el-pagination>
            </div>
        </div>

    </div> 
</template>
<script>

import tableComponent from '@/components/table'
import { wineWarehouseApi } from '@/api'
import {customFormatFullTime} from '@/assets/js/utils'
// 存酒查询
import cjcx from './components/cjcx'
// 待存入
import dcr from './components/dcr'
// 待取酒
import dqj from './components/dqj'
// 已取出
import yqc from './components/yqc'
// 已存入
import ycr from './components/ycr'
// 已过期
import ygq from './components/ygq'
// 已过期入库
import ygqrk from './components/ygqrk'
// 存酒拒绝
import cjjj from './components/cjjj'
// 取酒拒绝
import qjjj from './components/qjjj'
// 取酒查询
import qjcx from './components/qjcx'

export default {
    name:'wine-warehouse-page',
    components:{
        tableComponent,
        cjcx,
        dcr,
        dqj,
        yqc,
        ygq,
        ycr,
        ygqrk,
        cjjj,
        qjjj,
        qjcx
    },
    data(){
        return {
            search_config:{
                form:{
                    time_type:2,
                    storage_station:'',
                    take_station:'',
                    alcohol_sub_odd_num:'',
                    member:'',
                    wine_storage_num:'',
                    times:[]
                },
                type_options:[
                    {
                        id:1,
                        label:'系统时间'
                    },
                    {
                        id:2,
                        label:'营业日'
                    }
                ],
            },
            tab_config:{
                tab_id:0,
                tab_name:'cjcx',
                list:[
                    {
                        id:0,
                        label:'存酒查询',
                        name:'cjcx'
                    },
                    {
                        id:1,
                        label:'待存入',
                        name:'dcr'
                    },
                    {
                        id:4,
                        label:'已存入',
                        name:'ycr'
                    },
                    {
                        id:9,
                        label:'存酒拒绝',
                        name:'cjjj'
                    },
                    {
                        id:5,
                        label:'已过期',
                        name:'ygq'
                    },
                    {
                        id:6,
                        label:'已过期入库',
                        name:'ygqrk'
                    },
                    {
                        id:-1,
                        label:'取酒查询',
                        name:'qjcx'
                    },
                    {
                        id:2,
                        label:'待取酒',
                        name:'dqj'
                    },
                    {
                        id:3,
                        label:'已取出',
                        name:'yqc'
                    },
                    {
                        id:10,
                        label:'取酒拒绝',
                        name:'qjjj'
                    },
                    
                ]
            }, 
            pagination:{
                page:1,
                page_size:10,
                total:0,
                page_sizes:[10,20,30]
            },
            table:{
                list:[]
            },
        }
    },
    watch:{
        'tab_config.tab_id':{
            handler(newVal){
                this.$nextTick(()=>{
                    this.resetFun()
                })
            },
            deep:true
        }
    },
    mounted(){
        this.getList()
    },
    methods:{
        typeChange(){
            this.search_config.form.times = []
        },
        /**
         * 每个类型显示对应的筛选条件 返回Boolean值
         */
        isShow(name){
            switch(parseInt(this.tab_config.tab_id)){
                case -1: //取酒查询
                    if(name == 'take_alcohol_odd_num' || name == 'take_station' || name == 'member' || name == 'wine_storage_num'){
                        return true
                    }
                    break;
                case 0: //存酒查询
                    if(name == 'alcohol_sub_odd_num' || name == 'storage_station' || name == 'member'){
                        return true
                    }
                    break;
                case 1: //待存入
                    if(name == 'alcohol_sub_odd_num' || name == 'storage_station' || name == 'member'){
                        return true
                    }
                    break;
                case 2: //待取酒
                    if(name == 'take_alcohol_odd_num' || name == 'take_station' || name == 'member' || name == 'wine_storage_num'){
                        return true
                    }
                    break;
                case 3: //已取出
                    if(name == 'take_alcohol_odd_num' || name == 'take_station' || name == 'member' || name == 'wine_storage_num'){
                        return true
                    }
                    break;
                case 4: //已存入
                    if(name == 'alcohol_sub_odd_num' || name == 'storage_station' || name == 'member'){
                        return true
                    }
                    break;
                case 5: //已过期
                    if(name == 'alcohol_sub_odd_num' || name == 'storage_station' || name == 'member'){
                        return true
                    }
                    break;
                case 6: //已过期入库
                    if(name == 'alcohol_sub_odd_num' || name == 'storage_station' || name == 'member'){
                        return true
                    }
                    break;
                case 9: //存酒拒绝
                    if(name == 'alcohol_sub_odd_num' || name == 'storage_station' || name == 'member'){
                        return true
                    }
                    break;
                case 10: //取酒拒绝
                    if(name == 'take_alcohol_odd_num' || name == 'storage_station' || name == 'member' || name == 'wine_storage_num'){
                        return true
                    }
                    break;
            }
            return false
        },
        /**
         * 搜索
         */
        searchFun(){
            this.getList()
        },
        /**
         * 重置
         */
        resetFun(){
            this.resetSearchConfigParams()
            this.getList()
        },
        resetSearchConfigParams(){
            this.pagination.page = 1
            this.search_config.form.time_type = 2
            this.search_config.form.storage_station = ''
            this.search_config.form.take_station = ''
            this.search_config.form.alcohol_sub_odd_num = ''
            this.search_config.form.take_alcohol_odd_num = ''
            this.search_config.form.member = ''
            this.search_config.form.wine_storage_num = ''
            this.search_config.form.times = []
        },
        /**
         * 列表
         */
        getList(){
            this.table.list = []
            let form = JSON.parse(JSON.stringify(this.search_config.form))
            form['start_time'] = form.times[0]
            form['end_time'] = form.times[1]
            delete form.times

            
            let isTakeWine = ''
            switch(parseInt(this.tab_config.tab_id || 0)){
                case 2:
                case 3:
                case 10:
                case -1:
                    isTakeWine = 1
                    break;
            }

            let params = {
                status:this.tab_config.tab_id,
                ...form,
                isTakeWine,
                page:this.pagination.page,
                pageSize:this.pagination.page_size,
            }
            wineWarehouseApi.wineShowList(params).then(res=>{
                this.pagination.total = res.data.total
                for(let i in res.data.list){
                    if(res.data.list[i].created_at){
                        res.data.list[i].created_at = customFormatFullTime(res.data.list[i].created_at,'YYYY-MM-DD h:m:s')
                    }
                }
                this.table.list = res.data.list
            })
        },
        handleSizeChange(page_size){
            this.pagination.page_size = page_size
            this.getList()
        },
        handleCurrentChange(page){
            this.pagination.page = page
            this.getList()
        },
        handleClick(){
            for(let i in this.tab_config.list){
                if(this.tab_config.list[i].id == this.tab_config.tab_id){
                    this.tab_config.tab_name = this.tab_config.list[i].name
                    break;
                }
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.wine-warehouse-page{
    padding-bottom:50px;
    .pagination-box{
        text-align: center;
        margin:50px 0px 20px;
    }
    .tab-box-containter{
        margin:20px;
        padding:20px;
        background-color: white;
    }
    .commodity-table{
        span{
            margin-right: 5px;
        }
        .el-progress{
            width: 100%;
        }
    }
    .dialog-allowance-num-hint{
        text-align: center;
        color: rgba(204, 20, 20, 1);
        text-align: center;
        margin: 20px 0px;
    }
    .dialog-allowancelist-title{
        font-weight: bold;
        margin-bottom:10px;
    }
    .search-input{
        border:none;
        & /deep/ .el-input__inner{
            border:none;
            border-right: 1px solid rgba(0,0,0,.1);
        }
    }
    .order-type{
        width:100px;
    }
}
</style>